<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadCrumbs" />
    <v-container fluid class="profile-section">
      <router-view></router-view>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

export default {
  name: "Profile",
  components: { Breadcrumb },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/spesa-online", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 1; i < pathArray.length; ++i) {
        // from 1 to skip "Area personale"
        breadcrumb = `${breadcrumb}${"/profile/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
